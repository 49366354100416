<template>
  <ion-app>
    <Menu v-if="menu"></Menu>
    <ion-router-outlet id="main-content"/>
  </ion-app>
</template>

<script>
import Menu from "@/components/menu"
import { defineComponent } from 'vue'
import { IonApp, IonRouterOutlet } from '@ionic/vue'


export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
    Menu
  },
  watch: {
    '$route' (from, to) {
      if(from.name == 'Landing' || to.name == 'Landing') {
        this.menu = false
      }else{
        this.menu = true
      }
      document.title = to.meta.title ? 'doo.id - ' + to.meta.title : 'doo.id'
    }
  },
  data () {
    return {
      menu: true,
    }
  }
})
</script>

<style>

</style>
