import auth from '../middleware/auth'

export default [
  {
    path: '/setting',
    name: 'Setting',
    component: () => import("@/views/user/setting.vue"),
    meta: {
      middleware: [auth],
      title: 'Pengaturan Akun'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import("@/views/user/profile.vue"),
    meta: {
      middleware: [auth],
      title: 'Profil Akun'
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import("@/views/user/change-password.vue"),
    meta: {
      middleware: [auth],
      title: 'Ubah Password Akun'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import("@/views/user/reset-password.vue"),
    meta: {
      middleware: [auth],
      title: 'Reset Password'
    }
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import("@/views/user/address.vue"),
    meta: {
      middleware: [auth],
      title: 'Alamat Pengiriman'
    }
  },
  {
    path: '/referral',
    name: 'Referral',
    component: () => import('@/views/user/referral.vue'),
    meta: {
      middleware: [auth],
      title: 'Referral Anda'
    }
  }
]
