// import auth from '../middleware/auth'

export default [
  {
    path: '/event',
    name: 'Event',
    component: () => import("@/views/event/index.vue"),
    meta: {
      // middleware: [auth],
      title: 'Event'
    }
  },
  {
    path: '/event/:id',
    name: 'EventDetail',
    component: () => import("@/views/event/detail.vue"),
    meta: {
      // middleware: [auth]
    }
  },
]
