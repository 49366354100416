const numFormat = (val) => {
  return val.toLocaleString()
}

const dateFormat = (val, f = '') => {
  let date = new Date(val);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  if(f=='full') {
    options.hour = 'numeric'
    options.minute = 'numeric'
    // options.second = 'numeric'
  }
  return date.toLocaleDateString('id-ID', options)
}

const maskCoupon = (val) => {
  let c = val.split('-');
  return c[3] ? c[3] : c[2];
}

const maskEmail = (email) => {
  var mask = email.replace(/^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
  );
  return mask;
}

const maskPhone = (phone) => {
  var mask = phone.slice(phone.length - 3)
  let s = '*';
  for(let i=1; i < phone.length - 3; i++){
    s +='*';
  }
  return s + mask;
}

export { numFormat, dateFormat, maskEmail, maskPhone, maskCoupon}
