import { createStore } from 'vuex'
import auth from './auth'
import quiz from './quiz'
import ad5 from './ad5'
import setting from './setting'

// Create a new store instance.
const store = createStore({
  state: () => ({
    modal: [],
    i: 0
  }),
  mutations: {
    setModal(state, modal) {
      state.modal[state.i] = modal
      state.i++
    },
  },
  modules: {
    auth: auth,
    quiz: quiz,
    ad5: ad5,
    setting: setting
  }
})

export default store
