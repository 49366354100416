import auth from '../middleware/auth'
// import verified from '../middleware/verified'

export default [
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import("@/views/quiz/index.vue"),
    meta: {
      middleware: [auth],
      title: 'Kuis'
    }
  },
  {
    path: '/quiz/:id/result',
    name: 'QuizResult',
    component: () => import("@/views/quiz/result.vue"),
    meta: {
      middleware: [auth],
      title: 'Kuis'
    }
  }
]
