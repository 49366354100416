import guest from '../middleware/guest'
import auth from '../middleware/auth'

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/auth/login.vue"),
    meta: {
      middleware: [ guest ],
      title: 'Login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/auth/register.vue"),
    meta: {
      middleware: [ guest ],
      title: 'Register'
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import("@/views/auth/verify.vue"),
    meta: {
      middleware: [auth],
      title: 'Email verification'
    }
  },
  {
    path: '/send-link-verification',
    name: 'SendLinkVerification',
    component: () => import("@/views/auth/send-verification-link.vue"),
    meta: {
      middleware: [auth],
      title: 'Email verification'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import("@/views/auth/forgot-password.vue"),
    meta: {
      // middleware: [guest],
      title: 'Lupa Password'
    }
  },
  {
    path: '/link-send-success',
    name: 'ForgotPasswordLinkSendSuccess',
    component: () => import("@/views/auth/link-sending.vue"),
    meta: {
      // middleware: [guest],
      title: 'Lupa Password'
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import("@/views/auth/signin.vue"),
    meta: {
      middleware: [guest],
      title: 'SignIn'
    }
  },
  {
    path: '/r',
    name: 'ReferralBy',
    component: () => import("@/views/auth/referral.vue"),
    meta: {
      middleware: [guest],
      title: 'Referral By'
    }
  }
]
