
import store from "@/store";

const initCurrentUser = async (to, from, next)  =>{

  let setting = store.getters['setting/getSetting']
  if (setting){
    await store.dispatch('setting/fetch')
  }

  let isLoggedIn = store.getters['auth/getLoggedIn']
  if (! isLoggedIn) {
    try {
      await store.dispatch('auth/fetchUser')
      next()
    } catch (e) {
      next()
    }
  } else {
    next()
  }
}

export default initCurrentUser
