import auth from '../middleware/auth'

export default [
  {
    path: '/coupon',
    name: 'Coupon',
    component: () => import("@/views/coupon/index.vue"),
    meta: {
      middleware: [auth],
      title: 'Kupon Saya'
    }
  },
  {
    path: '/coupon/:id',
    name: 'CouponDetail',
    component: () => import("@/views/coupon/detail.vue"),
    meta: {
      middleware: [auth],
      title: 'Kupon Undian'
    }
  }
]
