import auth from '../middleware/auth'

export default [
  {
    path: '/claim',
    name: 'ClaimList',
    component: () => import("@/views/claim/index.vue"),
    meta: {
      middleware: [auth],
      title: 'Hadiah Saya'
    }
  },
  {
    path: '/claim/:id',
    name: 'Claim',
    component: () => import("@/views/claim/claim.vue"),
    meta: {
      middleware: [auth],
      title: 'Klaim Hadiah'
    }
  },
  {
    path: '/claim/:id/status',
    name: 'ClaimStatus',
    component: () => import("@/views/claim/detail.vue"),
    meta: {
      middleware: [auth],
      title: 'Hadiah Saya'
    }
  }
]
