<template>
  <ion-menu menu-id="menu" content-id="main-content">
    <ion-content >
      <div class="card-profile">
        <div class="profile">
          <div class="username">{{ $auth ? $auth.name : 'Guest!' }}</div>
          <div class="email">{{ $auth? $auth.email : '' }}</div>
        </div>
        <div class="point-menu" @click="goTo('/history')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M11.9794 23.9587C18.5954 23.9587 23.9588 18.5954 23.9588 11.9794C23.9588 5.36334 18.5954 -1.52588e-05 11.9794 -1.52588e-05C5.36335 -1.52588e-05 0 5.36334 0 11.9794C0 18.5954 5.36335 23.9587 11.9794 23.9587Z" fill="#FFBC51"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9797 2.43952C12.3952 2.43952 12.8045 2.466 13.206 2.5176C13.5726 2.56462 13.9084 2.30529 13.9555 1.93872C14.0027 1.57214 13.7433 1.23629 13.3767 1.18922C12.9193 1.13041 12.453 1.1001 11.9797 1.1001C11.6101 1.1001 11.3101 1.40015 11.3101 1.76974C11.3101 2.13956 11.6101 2.43952 11.9797 2.43952Z" fill="#FCD28A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8733 2.88701C17.2311 3.6376 19.1921 5.28124 20.3591 7.41888C20.5361 7.7435 20.9433 7.86312 21.2677 7.68597C21.5921 7.50882 21.7117 7.10156 21.5346 6.7774C20.2041 4.3398 17.9678 2.46697 15.2797 1.61082C14.9276 1.49864 14.5506 1.6935 14.4384 2.04556C14.3263 2.39803 14.5212 2.7747 14.8733 2.88701Z" fill="#FCD28A"/>
            <path d="M11.9788 20.5844C16.7314 20.5844 20.5841 16.7317 20.5841 11.9792C20.5841 7.22661 16.7314 3.3739 11.9788 3.3739C7.22624 3.3739 3.37354 7.22661 3.37354 11.9792C3.37354 16.7317 7.22624 20.5844 11.9788 20.5844Z" fill="#FFB32C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04716 19.6344C5.27264 18.2057 3.37354 15.3123 3.37354 11.9791C3.37354 7.22997 7.22945 3.3739 11.9788 3.3739C14.0876 3.3739 16.0201 4.13408 17.5176 5.39569C16.3391 4.78819 15.0022 4.44533 13.586 4.44533C8.83659 4.44533 4.98068 8.3014 4.98068 13.0505C4.98068 15.6916 6.1727 18.0557 8.04716 19.6344Z" fill="#F49E17"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3146 7.14659C11.4346 6.88772 11.6939 6.72217 11.9791 6.72217C12.2642 6.72217 12.5235 6.88772 12.6436 7.14659L13.8886 9.82854L16.8243 10.184C17.1074 10.2181 17.3449 10.4134 17.433 10.685C17.5211 10.9562 17.4439 11.2539 17.235 11.4478L15.0688 13.461L15.6381 16.3626C15.693 16.6425 15.5805 16.9286 15.3498 17.0964C15.1191 17.2641 14.8121 17.2826 14.5629 17.1436L11.9791 15.7058L9.39527 17.1436C9.1461 17.2826 8.83909 17.2641 8.60836 17.0964C8.37767 16.9286 8.26519 16.6425 8.32009 16.3626L8.88934 13.461L6.72319 11.4478C6.51428 11.2539 6.43702 10.9562 6.52514 10.685C6.61329 10.4134 6.85077 10.2181 7.13389 10.184L10.0695 9.82854L11.3146 7.14659Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1618 10.0594C9.27244 13.1575 12.1111 15.4359 15.5141 15.7305L15.6381 16.3627C15.693 16.6423 15.5805 16.9284 15.3498 17.0961C15.1191 17.2637 14.812 17.2825 14.5629 17.1437L11.9791 15.7053L9.39528 17.1437C9.14606 17.2825 8.8391 17.2637 8.60837 17.0961C8.37764 16.9284 8.26519 16.6423 8.3201 16.3627L8.88935 13.4607L6.72319 11.448C6.51431 11.2536 6.43701 10.9562 6.52513 10.6846C6.61326 10.4136 6.85079 10.218 7.13386 10.1837L8.1618 10.0594Z" fill="#E9F2FE"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          {{ $filters.numFormat($auth ? $auth.point : 0) }}
        </div>
      </div>
      <div class="referral" @click="goTo('/referral')" v-if="$auth">
        <div class="icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2969 11.2969H21.1875C20.7992 11.2969 20.4844 11.6117 20.4844 12C20.4844 12.3883 20.7992 12.7031 21.1875 12.7031H23.2969C23.6852 12.7031 24 12.3883 24 12C24 11.6117 23.6852 11.2969 23.2969 11.2969Z" fill="#EB5757"/>
            <path d="M22.3878 15.7216L20.9816 14.3153C20.707 14.0408 20.2618 14.0408 19.9872 14.3153C19.7126 14.5899 19.7126 15.0351 19.9872 15.3097L21.3935 16.7159C21.668 16.9905 22.1133 16.9905 22.3878 16.7159C22.6624 16.4414 22.6624 15.9962 22.3878 15.7216Z" fill="#EB5757"/>
            <path d="M22.3878 7.2841C22.1133 7.00955 21.668 7.00955 21.3935 7.2841L19.9872 8.69035C19.7126 8.96494 19.7126 9.41011 19.9872 9.68471C20.2618 9.9593 20.707 9.95925 20.9816 9.68471L22.3878 8.27846C22.6624 8.00386 22.6624 7.55869 22.3878 7.2841Z" fill="#EB5757"/>
            <path d="M16.2656 2.85938C15.2095 2.85938 14.3323 3.63961 14.1798 4.65394L13.5384 5.29533C12.4221 6.41166 10.7781 7.07812 9.14062 7.07812H4.92188C4.00523 7.07812 3.22369 7.66598 2.93344 8.48438H2.8125C1.26169 8.48438 0 9.74606 0 11.2969C0 12.8477 1.26169 14.1094 2.8125 14.1094H2.93344C3.14564 14.7077 3.62044 15.1825 4.21875 15.3947V19.0312C4.21875 20.1944 5.16506 21.1406 6.32817 21.1406C7.49123 21.1406 8.4375 20.1944 8.4375 19.0312V15.5156H9.14062C10.7781 15.5156 12.4221 16.1821 13.5384 17.2984L14.1798 17.9398C14.3323 18.9541 15.2095 19.7344 16.2657 19.7344C17.4287 19.7344 18.375 18.7881 18.375 17.625V4.96875C18.375 3.80564 17.4287 2.85938 16.2656 2.85938ZM2.8125 12.7031C2.03709 12.7031 1.40625 12.0723 1.40625 11.2969C1.40625 10.5215 2.03709 9.89062 2.8125 9.89062V12.7031ZM7.03125 19.0312C7.03125 19.419 6.71583 19.7344 6.32812 19.7344C5.94042 19.7344 5.625 19.419 5.625 19.0312V15.5156H7.03125V19.0312ZM8.4375 14.1094H4.92188C4.53417 14.1094 4.21875 13.794 4.21875 13.4062V9.1875C4.21875 8.7998 4.53417 8.48438 4.92188 8.48438H8.4375V14.1094ZM14.1562 15.9537C12.9684 14.9257 11.4412 14.2869 9.83906 14.1412L9.83911 8.45245C11.4412 8.30686 12.9684 7.66809 14.1562 6.63998V15.9537ZM16.9688 17.625C16.9688 18.0127 16.6533 18.3281 16.2657 18.3281H16.2656C15.8779 18.3281 15.5625 18.0127 15.5625 17.625V4.96875C15.5625 4.58105 15.8779 4.26562 16.2657 4.26562C16.6533 4.26562 16.9688 4.58105 16.9688 4.96875V17.625Z" fill="#EB5757"/>
          </svg>
        </div>
        <div class="ki13">
          <div class="bold">Dapatkan Poin!</div>
          <span class="text-sm">Bagikan kode referal</span>
        </div>
      </div>
      <ion-list lines="none">
        <ion-item button detail @click="goTo('/news')">
          <ion-label>News</ion-label>
        </ion-item>
        <ion-item button detail @click="goTo('/event')">
          <ion-label>Giveaway</ion-label>
        </ion-item>
        <ion-item button detail @click="goTo('/testimoni')">
          <ion-label>Testimoni</ion-label>
        </ion-item>
        <ion-item button detail @click="goTo('/store')">
          <ion-label>Store</ion-label>
        </ion-item>
        <ion-item v-if="$auth" button detail @click="goTo('/coupon')">
          <ion-label>Kupon Saya</ion-label>
        </ion-item>
        <ion-item v-if="$auth" button detail @click="goTo('/claim')">
          <ion-label>Hadiah Saya</ion-label>
        </ion-item>

        <ion-item button detail @click="goTo(page.slug)" v-for="(page, i) in pages" :key="i">
          <ion-label>{{ page.title }}</ion-label>
        </ion-item>

        <ion-item v-if="$auth" button detail @click="goTo('/setting')">
          <ion-label>Pengaturan Akun</ion-label>
        </ion-item>

        <!-- <ion-item button detail @click="goTo('/about')">
          <ion-label>Tentang doo.id</ion-label>
        </ion-item> -->

      </ion-list>
    </ion-content>
    <ion-footer class="ion-no-border footer-line">
      <ion-toolbar>
        <ion-list lines="none">
          <ion-item button @click="logout" v-if="$auth">
            <ion-label>Keluar</ion-label>
            <ion-note slot="end">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6666 11.3333L14 7.99999L10.6666 4.66666" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14 8H6" stroke="#EB5757" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </ion-note>
          </ion-item>
          <ion-item button @click="goTo('/auth')" v-if="! $auth">
            <ion-label>Login</ion-label>
          </ion-item>
        </ion-list>
      </ion-toolbar>
    </ion-footer>

  </ion-menu>
</template>
<script>
import {
  IonMenu,IonContent,IonItem,IonList,IonLabel, menuController,
  IonFooter, IonToolbar, IonNote
} from "@ionic/vue";
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'


export default defineComponent({
  components: {
    IonMenu,IonContent,IonItem,IonList,IonLabel,
    IonFooter, IonToolbar, IonNote
  },
  data () {
    return {
      pages: []
    }
  },
  created () {
    this.getPages()
  },
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout')
      await menuController.toggle()
      this.$router.push('/')
    },
    async goTo(page) {
      await menuController.toggle()
      this.$router.push(page)
    },
    async getPages() {
      this.pages = await this.$api.get('/api/pages').then(res => res.data);
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
})
</script>

<style lang="scss" scoped>
.card-profile {
  padding: 16px;
  .profile {
    margin-bottom: 1rem;
    margin-top: 52px;
    .username {
      font-weight: bold;
      line-height: 22px;
    }
    .email {
      line-height: 19px;
      font-size: 14px;
      color: #545454;
    }
  }
  .point-menu {
    display: flex;
    justify-content: left;
    align-items: center;
    color: #fff;
    background: #000;
    height: 44px;
    border-radius: 5px;
    padding: 10px;
    svg {
      margin-right: 15px;
    }
  }
}
.footer-line {
  border-top: 1px solid #dddd;
}

.referral {
  display: flex;
  margin: 0 1rem;
  align-content: center;
  align-items: center;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
  cursor: pointer;
  .icon {
    flex: 0;
    padding: 15px 0 15px 18px;
  }
  .ki13 {
    flex: 1;
    padding: 10px;
  }
}
</style>
