import auth from '../middleware/auth'

export default [
  {
    path: '/testimoni',
    name: 'Testimoni',
    component: () => import("@/views/testimoni/index.vue"),
    meta: {
      // middleware: [auth],
      title: 'Testimoni'
    }
  },
  {
    path: '/testimoni/:id',
    name: 'TestimoniDetail',
    component: () => import("@/views/testimoni/detail.vue"),
    meta: {
      // middleware: [auth]
    }
  },
  {
    path: '/testimoni/:id/create',
    name: 'TestimoniCreate',
    component: () => import("@/views/testimoni/create.vue"),
    meta: {
      middleware: [auth]
    }
  },
]
