export default [
  {
    path: '/post/:slug',
    name: 'PostDetail',
    component: () => import("@/views/post/detail.vue"),
    meta: {
      title: 'Post'
    }
  },
  {
    path: '/:slug',
    name: 'PostPage',
    component: () => import("@/views/post/page.vue"),
    meta: {
      title: 'Post'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import("@/views/post/index.vue"),
    meta: {
      title: 'Post'
    }
  }
]
