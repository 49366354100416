import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from './plugins/axios'
import store from './store'
import * as filters from './plugins/filters'

import { IonicVue } from '@ionic/vue'
import VueGtag from "vue-gtag-next"

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/app.scss';
// pwa register
import './registerServiceWorker'

var TRACKER_ID = 'G-5VL91XN8DR';

let user = store.getters['auth/getLoggedIn']

const app = createApp(App)
  .use(IonicVue, {
    mode: 'md'
  })
  .use(store)
  .use(router)
  .use(VueGtag, {
    property: {
      id: TRACKER_ID,
      params: {
        user_id: user ? user.id : null,
        send_page_view: false,
      }
    }
  });

app.config.globalProperties.$api = axios
app.config.globalProperties.$filters = filters,
app.config.globalProperties.$uploadUrl = 'https://dash.doo.id/api/upload';

router.isReady().then(() => {
  app.mount('#app');
});
