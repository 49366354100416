import api from '@/plugins/axios'

const quiz = {
  namespaced: true,
  state: () => ({
    quiz: null,
    questions: null,
    answers: []
  }),
  mutations: {
    setQuiz(state, data) {
      state.quiz = data
    },
    setQuestion(state, data) {
      state.questions = data
    },
    setAnswer(state, data) {
      let i = state.answers.findIndex((val) => {
        return val.question_id == data.question_id
      })
      if(i >= 0) {
        state.answers.splice(i, 1);
      }
      state.answers.push(data)
    },
    reset(state) {
      state.quiz= null,
      state.questions= null,
      state.answers= []
    }
  },
  actions: {
    async fetchQuestion({commit}) {
      return await api.get('/api/question').then(res => {
        commit('setQuestion', res.data)
        return res
      })
    },
    async quizStart({commit}) {
      return await api.post('/api/quiz/start').then(res => {
        commit('setQuiz', res.data)
        return res
      })
    },
    async submitQuiz({commit, state}) {
      if(state.quiz){
        let data  = {
          quiz_id: state.quiz.id,
          answers: state.answers
        }
        return await api.post('/api/quiz/finish', data).then(res => {
          commit('reset')
          return res
        })
      }
    }
  },
  getters: {
    getQuestion(state) {
      return state.questions
    },
    getSessionQuiz(state) {
      return state.quiz
    }
  }
}

export default quiz
