import api from '@/plugins/axios'

const setting = {
  namespaced: true,
  state: () => ({
    setting: {}
  }),
  mutations: {
    setSetting(state, data) {
      state.setting = data
    }
  },
  actions: {
    async fetch({ commit }) {
      return await api.get('/api/setting').then(res => {
        commit('setSetting', res.data)
        return res
      })
    }
  },
  getters: {
    getSetting(state) {
      return state.setting;
    }
  }
}

export default setting;
