import { createRouter, createWebHistory, createMemoryHistory } from '@ionic/vue-router';
import { trackRouter } from "vue-gtag-next"

import authRoute from './mod/auth'
import quizRoute from './mod/quiz'
import eventRoute from './mod/event'
import couponRoute from './mod/coupon'
import claimRoute from './mod/claim'
import userRoute from './mod/user'
import storeRoute from './mod/store'
import postRoute from './mod/post'
import testimoniRoute from './mod/testimoni'

import guest from './middleware/guest'
import auth from './middleware/auth'
import middleware from './middleware/middleware'
import initCurrentUser from './middleware/initCurrenUser'

const isServer = typeof window === 'undefined';
const history = isServer ? createMemoryHistory() : createWebHistory(process.env.BASE_URL);

const baseRoutes = [
  {
    path: '/landing',
    name: 'Landing',
    component: () => import('@/views/landing.vue'),
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home3.vue'),
    meta: {
      // middleware: [auth]
    }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/views/user/notification.vue'),
    meta: {
      middleware: [auth],
      title: 'Notifikasi'
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/user/history.vue'),
    meta: {
      middleware: [auth],
      title: 'Riwayat Poin'
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/auth/auth.vue'),
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about.vue'),
    meta: {
      title: 'Tentang kami'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/terms.vue'),
    meta: {
      title: 'Persyaratan Layanan'
    }
  },
  {
    path: '/policy',
    name: 'Policy',
    component: () => import('@/views/policy.vue'),
    meta: {
      title: 'Kebijakan Pribadi'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import("@/views/404.vue")
  }
]

const routes = baseRoutes.concat(
  authRoute,
  quizRoute,
  eventRoute,
  couponRoute,
  claimRoute,
  userRoute,
  storeRoute,
  postRoute,
  testimoniRoute
);

const router = createRouter({
  history: history,
  routes
})

router.beforeEach(initCurrentUser)
router.beforeEach(middleware)

trackRouter(router, {
  template(to) {
    return {
      page_title: to.name,
      page_path: to.path
    }
  }
});

export default router
