import api from '@/plugins/axios'

const ad5 = {
  namespaced: true,
  state: () => ({
    ads: null
  }),
  mutations: {
    setAds(state, data) {
      state.ads = data
    }
  },
  actions: {
    async fetchAds({commit}) {
      return await api.get('/api/ads').then(res => {
        commit('setAds', res.data)
        return res
      })
    }
  },
  getters: {
    getAdsLoad(state) {
      return state.ads;
    }
  }
}

export default ad5;
