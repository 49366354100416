import axios from 'axios'
import store from '../store'
// import { toastController } from '@ionic/vue';

const api = axios.create({
  baseURL: "https://api.doo.id"
  // baseURL: 'http://localhost:8080'
});

api.interceptors.request.use(function (config) {
    let token = store.state.auth.token || localStorage.getItem("token");
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  // if (response.status == 200 && response.data.error){
  //     let message = response.data.message
  //     if (response.data.error == 422 && message == undefined) {
  //       let err = response.data.data
  //       let x = err[Object.keys(err)[0]]
  //       message = Object.keys(err)[0] + ': ' + x.join();
  //     }

  //   if (response.data.error == 422 && message !== '') {
  //       toastController
  //           .create({
  //           message: message,
  //           duration: 2000,
  //           color: 'danger',
  //           }).then(r => r.present())
  //     }
  //   }
    return response.data;
}, function (error) {
    return Promise.reject(error);
});

export default api
