import auth from '../middleware/auth'

export default [
  {
    path: '/store',
    name: 'Store',
    component: () => import("@/views/store/index.vue"),
    meta: {
      // middleware: [auth],
      title: 'Store'
    }
  },
  {
    path: '/product/:id',
    name: 'ProductDetail',
    component: () => import("@/views/store/product.vue"),
    meta: {
      // middleware: [auth],
      title: 'Product Detail'
    }
  },
  {
    path: '/product/:id/buy',
    name: 'BuyProduct',
    component: () => import("@/views/store/buy.vue"),
    meta: {
      middleware: [auth],
      title: 'Product Detail'
    }
  },
  {
    path: '/store/transaction',
    name: 'StoreTransaction',
    component: () => import("@/views/store/transaction.vue"),
    meta: {
      middleware: [auth],
      title: 'Transaksi'
    }
  },
  {
    path: '/store/transaction/:id',
    name: 'StoreTransactionDetail',
    component: () => import("@/views/store/detail.vue"),
    meta: {
      middleware: [auth],
      title: 'Detil Pesanan'
    }
  }
]
