import api from '@/plugins/axios'
import { toastController } from '@ionic/vue';

const auth = {
  namespaced: true,
  state: () => ({
    verified: false,
    user: null,
    token: null,
    expires: null
  }),
  mutations: {
    setToken(state, payload) {
      const now = new Date();
      let expires = now.getTime() + (payload.ttl * 1000) - (60 * 1000)
      state.token = payload.token
      state.expires = expires

      localStorage.setItem("token", payload.token);
      localStorage.setItem("token.expires", expires)
    },
    setUser(state, payload) {
      state.user = payload
      state.verified = payload.verified
    },
    async reset(state) {
      state.user = null
      state.token = null
      state.expires = null
      await localStorage.removeItem("token")
      await localStorage.removeItem("token.expires")
    }
  },
  actions: {
    async login({commit, dispatch}, data) {
      return await api.post('/api/login', data)
        .then(res => {
          if (res.error) {
            commit('reset')
            toastController.create({
              message: res.message,
              duration: 2000,
              color: 'danger',
            }).then(r => r.present())
            return;
          }
          commit('setToken', res.data)
          dispatch('fetchUser')
          return res
        })
        .catch(err => {
          commit('reset')
          return err.response
        })
    },
    async fetchUser({ commit, dispatch, state }) {
      let ttl = state.expires || localStorage.getItem("token.expires")
      const now = new Date();
      if (state.token && now.getTime() > ttl ) {
        dispatch('refreshToken')
      }

      return await api.get('/api/me').then(res => {
        commit('setUser', res.data)
        return res
      }).catch(err => {
        if(err.response.status == 401){
          commit('reset')
        }
      })
    },
    async refreshToken({commit, dispatch}) {
      return await api.post('/api/refresh-token').then(res => {
        commit('setToken', res.data)
        dispatch('fetchUser')
        return res
      }).catch(err => {
        commit('reset')
        return err.response
      })
    },
    async register({commit, dispatch}, data) {
      return await api.post('/api/register', data).then(res => {
        commit('setToken', res.data)
        dispatch('fetchUser')
        localStorage.removeItem("referral_by")
        return res
      }).catch(err => {
        commit('reset')
        return err.response
      })
    },
    async logout({commit}) {
      return await api.delete('/api/logout').then(res => {
        commit('reset')
        return res
      })
    }
  },
  getters: {
    getLoggedIn (state) {
      if(! state.user)
        return null

      state.user.ref_link = `doo.id/r?by=${state.user.referral_code}`

      return state.user;
    },
    isVerified (state) {
      return state.verified
    }
  }
}

export default auth
